<template>
    <div>
<!--        <div v-if="examinationsList.length > 0">-->
<!--            <el-form v-for="(item,index) in examinationsList" :key="index" label-width="100px" style="border-bottom: 1px solid #999;">-->
<!--                <el-form-item label="提交人：" style="margin-bottom: 0;">-->
<!--                    {{item.userName}}-->
<!--                </el-form-item>-->
<!--                <el-form-item label="问题描述：" style="margin-bottom: 0;">-->
<!--                    {{item.confirmProblem}}-->
<!--                </el-form-item>-->
<!--                <el-form-item label="所属规范：" style="margin-bottom: 0;">-->
<!--                    {{item.drStandardName}}-->
<!--                </el-form-item>-->
<!--                <el-form-item label="所属条文：" style="margin-bottom: 0;">-->
<!--                    {{item.drStandardItemName}}-->
<!--                </el-form-item>-->
<!--                <el-form-item label="图纸位置：" style="margin-bottom: 0;">-->
<!--                    {{item.drProjectFilePath}}-->
<!--                    <el-link v-if="item.positionImg && item.positionImg.length > 0" type="primary" @click="checkScreenshots(item.positionImg)">查看截图</el-link>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="审查内容：" style="margin-bottom: 0;">-->
<!--                    {{item.drAuditContentName}}-->
<!--                </el-form-item>-->

<!--            </el-form>-->
      <div>
          <el-tabs v-if="examinationsList.length > 0">
            <el-tab-pane v-for="(itemGroup, index) in groupedExaminations" :label="itemGroup[0].userName" :key="index">
              <div v-for="(item, itemIndex) in itemGroup" :key="itemIndex">
              <el-form label-width="100px" style="border-bottom: 1px solid #999;">
<!--	              <el-form-item v-if="itemGroup[0].userName === 'AI智能机器人'" label="" style="margin-bottom: 0;">-->
		             <template v-if="itemGroup[0].createUserId === 2&&itemIndex===0" >
			             <el-button type="primary" @click="formattedUserNames">跳转到图智</el-button>
		             </template>
<!--	              </el-form-item>-->
                <el-form-item label="提交人：" style="margin-bottom: 0;">
                  {{ item.userName }}
                </el-form-item>
                <el-form-item label="问题描述：" style="margin-bottom: 0;">
                  <span v-if="item.isReport" style="background-color:#B0E57C; padding: 2px 5px; border-radius: 3px;">已采纳</span>
                  <span v-else style="background-color: #FFC0CB; padding: 2px 5px; border-radius: 3px;">未采纳</span>
                   {{ item.confirmProblem }}
	                <el-tag v-if="item.isAi" type="success">AI</el-tag>
                </el-form-item>
                <el-form-item label="所属规范：" style="margin-bottom: 0;">
                  {{ item.drStandardName }}
                </el-form-item>
                <el-form-item label="所属条文：" style="margin-bottom: 0;">
                  {{ item.drStandardItemName }}
                </el-form-item>
                <el-form-item label="图纸位置：" style="margin-bottom: 0;">
                  {{ item.drProjectFilePath }}
                  <el-link v-if="item.positionImg && item.positionImg.length > 0" type="primary"
                           @click="checkScreenshots(item.positionImg)">查看截图
                  </el-link>
                </el-form-item>
                <el-form-item label="问题坐标：" style="margin-bottom: 0;" >
                  {{item.drawing}}
                </el-form-item >
                <el-form-item label="审查内容：" style="margin-bottom: 0;">
                  {{ item.drAuditContentName }}
                </el-form-item>
              </el-form>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div v-if="!examinationsList.length > 0">
            <div style="text-align: center;">暂无数据</div>
        </div>

        <el-dialog
        title="图纸截图"
        :visible.sync="showScreenshotVisible"
        v-model="showScreenshotVisible"
        :close-on-click-modal="false"
        :append-to-body="true"
        width='70%'
        >
            <div>
                <img :src="positionImg" width="100%" height="100%" />
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="showScreenshotVisible = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getOpinionListByWorkFlowId } from '@/api/api'
export default {
  computed: {
    groupedExaminations () {
      // 根据提交人名字分组
      const grouped = {};
      this.examinationsList.forEach((item) => {
        const userName = item.userName;
        if (!grouped[userName]) {
          grouped[userName] = [];
        }
        grouped[userName].push(item);
      });
      return Object.values(grouped);
    },
	  
  },
    data() {
        return {
            examinationsList: [
                {
                    confirmProblem: "",
                    confirmProblem1: "",
                    drStandardItemName: "",
                    confirmProblem2: "",
                    drAuditContentName: "",
                    userName:"",
                },
            ],
            showScreenshotVisible: false,
            positionImg:'',
        }
    },
    props:['projectId','flowNodeId','aiDomainUrl'],
    methods: {
	    formattedUserNames() {
		    window.open(this.aiDomainUrl);
	    },
        // 图审人员初审意见
        primaryExaminations() {
	        console.log(this._props.aiDomainUrl,'url')
	        const params = {
		        projectId: this._props.projectId,
		        workNodeId: this._props.flowNodeId
	        };
	        
	        getOpinionListByWorkFlowId(params).then(res => {
		        const result = res.data;
		        if(result.success && result.response) {
			        this.examinationsList = result.response
			    }else{
			        this.examinationsList = []
			    }
			})
        },
        // 查看截图
        checkScreenshots(positionImg) {
            this.showScreenshotVisible = true;
            this.positionImg = positionImg;
        },
    },
    watch: {
        'projectId': function() {
            this.primaryExaminations()
        },
				'flowNodeId': function() {
				    this.primaryExaminations()
				},
		    'aiDomainUrl': function() {
			    this.primaryExaminations()
		    }
    },
    created(){
        this.primaryExaminations()
    }
}
</script>

<style lang="stylus" scoped>

</style>
